
@font-face {
    font-family: 'Berlingske Typewriter';
    src: url('BerlingskeTypewriter-Tn.eot');
    src: url('BerlingskeTypewriter-Tn.eot?#iefix') format('embedded-opentype'), url('BerlingskeTypewriter-Tn.woff2') format('woff2'), url('BerlingskeTypewriter-Tn.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Berlingske Typewriter';
    src: url('BerlingskeTypewriter-Rg.eot');
    src: url('BerlingskeTypewriter-Rg.eot?#iefix') format('embedded-opentype'), url('BerlingskeTypewriter-Rg.woff2') format('woff2'), url('BerlingskeTypewriter-Rg.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Berlingske Typewriter';
    src: url('BerlingskeTypewriter-Bd.eot');
    src: url('BerlingskeTypewriter-Bd.eot?#iefix') format('embedded-opentype'), url('BerlingskeTypewriter-Bd.woff2') format('woff2'), url('BerlingskeTypewriter-Bd.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Berlingske Typewriter';
    src: url('BerlingskeTypewriter-BdIt.eot');
    src: url('BerlingskeTypewriter-BdIt.eot?#iefix') format('embedded-opentype'), url('BerlingskeTypewriter-BdIt.woff2') format('woff2'), url('BerlingskeTypewriter-BdIt.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Berlingske Typewriter';
    src: url('BerlingskeTypewriter-It.eot');
    src: url('BerlingskeTypewriter-It.eot?#iefix') format('embedded-opentype'), url('BerlingskeTypewriter-It.woff2') format('woff2'), url('BerlingskeTypewriter-It.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Berlingske Typewriter';
    src: url('BerlingskeTypewriter-TnIt.eot');
    src: url('BerlingskeTypewriter-TnIt.eot?#iefix') format('embedded-opentype'), url('BerlingskeTypewriter-TnIt.woff2') format('woff2'), url('BerlingskeTypewriter-TnIt.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}
